import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormElement from '../../../components/Form/FormElement';
import useAddData from '../../../hooks/useAddData';
import useGetData from '../../../hooks/useGetData';
import Layout from '../../../components/Layout';
import { useLocation,useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import useDownloadFile from '../../../hooks/useDownloadFile';
import { RxReload } from "react-icons/rx";
import { HiChevronLeft } from "react-icons/hi2";
const moment = require('moment');

const categoryInitialState = {
  limit: 100,
  pageNo: 0,
  searchQuery: ''
}





const monthsRange = {
  '1 month': 1,
  '3 months': 3,
  '6 months': 6,
  '12 months': 12
}

const UserDetail = ({ data, open, setOpen }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false)
  // console.log(state);
  // const { prop1, prop2 } = location.state;
  const [updatesData, setUpdatesData] = useState(state);
  // console.log(updatesData['name']);
  useEffect(() => {
    if (state) {
      console.log(state);
      setUpdatesData(state)
    }
  }, [state])
  const subscriptionListInitialState = {
    limit: 100,
    pageNo: 0,
    searchQuery: '',
    user_id: updatesData['userId']
  }
  const { setDataToServer } = useAddData('/addUserSubscription', () => setDialogOpen(false), 'getUserSubscriptionList')
  const { setDataToServer: updateSubscription } = useAddData('/updateUserSubscription', () => setEditDialogOpen(false), 'getUserSubscriptionList')
  const { handleGetDataList: subscriptionOption } = useGetData(categoryInitialState, 'getSubscriptionList')
  const { handleGetDataList: subscriptionList } = useGetData(subscriptionListInitialState, 'getUserSubscriptionList')

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let temp = {
      ...updatesData,
      id: updatesData['subscription_id'],
      user_id: updatesData['userId'],
      subscription_start_date: moment(updatesData['subscription_start_date']).format('YYYY/MM/DD')
    }
    setDataToServer.mutate(temp)
  }





  const handleSetFormData = (e) => {
    let value = e.target.value
    let name = e.target.name
    let data;
    let subscriptionData = subscriptionOption?.data?.data.find((x) => x.id === value)
    // console.log(subscriptionData);
    if (name === 'subscription_id') {
      data = {
        ...updatesData,
        [e.target.name]: value,
        subscription_price: subscriptionData.subscription_price,
        subscription_duration: subscriptionData.subscription_duration
      }
    } else {
      data = {
        ...updatesData,
        [e.target.name]: value
      }
    }
    setUpdatesData(data);
  }


  const setDate = (date) => {
    // take start date and map according to the duration of the subcription
    const subs = subscriptionOption.data.data.find(e => e.subscription_duration === updatesData.subscription_duration)
    const duration = monthsRange[subs.subscription_duration]
    const endDate = moment(date).add(duration, 'month').format('YYYY/MM/DD')
    // console.log(endDate);
    setUpdatesData({ ...updatesData, subscription_start_date: date, subscription_end_date: endDate })
  }
  const setUpdateDate = (date) => {
    // take start date and map according to the duration of the subcription
    const subs = subscriptionOption.data.data.find(e => e.subscription_duration === updatesData.subscription_duration)
    const duration = monthsRange[subs.subscription_duration]
    const endDate = moment(date).add(duration, 'month').format('YYYY/MM/DD')
    setEditTransactionData({ ...editTransactionData, subscription_start_date: date, subscription_end_date: endDate })
  }

  const handleSubscriptionOption = () => {
    return subscriptionOption?.data?.data?.map((item) => { return { name: item.subscription_type, value: item.id } })
  }

  const handleCloseModal = () => {
    setDialogOpen(false)
  }

  const { reportDownload } = useDownloadFile({
    url: 'generate_receipt',
    filename: 'Receipt.pdf'
  })
  const { reportDownload: downloadEntireReport } = useDownloadFile({
    url: 'user_transaction_report',
    filename: `Report_${updatesData['userId']}.xlsx`,
    withBlob: false
  })

  const handleGenerateBill = (e, transcation_id) => {
    e.stopPropagation();
    reportDownload.mutate({
      user_id: updatesData['userId'],
      transcation_id
    })
  }

  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [editTransactionData, setEditTransactionData] = useState({})

  const handleEditTransaction = (data) => {
    setEditTransactionData(data)
    setEditDialogOpen(true)
  }


  const handleUpdateFormSubmit = (e) => {
    e.preventDefault();
    let temp = {
      ...editTransactionData,
      id: editTransactionData['subscription_id'],
      user_id: editTransactionData['userId'],
      subscription_start_date: moment(editTransactionData['subscription_start_date']).format('YYYY/MM/DD')
    }
    updateSubscription.mutate(temp)
  }

  const handleEditSetFormData = (e) => {
    let value = e.target.value
    let name = e.target.name
    let data;
    let subscriptionData = subscriptionOption?.data?.data.find((x) => x.id === value)
    if (name === 'subscription_id') {
      data = {
        ...editTransactionData,
        [e.target.name]: value,
        subscription_price: subscriptionData.subscription_price
      }
    } else {
      data = {
        ...editTransactionData,
        [e.target.name]: value
      }
    }
    setEditTransactionData(data);
  }

  const handleDownloadReport = () => {
    downloadEntireReport.mutate({
      user_id: updatesData['userId'],
    })
  }

  const setPaymentDate = (val) => {
    const paymentEndDate = moment(val).format('YYYY/MM/DD')
    setUpdatesData({ ...updatesData, payment_date: paymentEndDate })
  }

  const setEditPaymentDate = (val) => {
    const paymentEndDate = moment(val).format('YYYY/MM/DD')
    setEditTransactionData({ ...editTransactionData, payment_date: paymentEndDate })
  }

  return (
    <>
      <Layout>
        <div>
          <div className=' flex items-center justify-between'>
            <div>
              <button onClick={() => navigate(-1)} className='flex items-center space-x-2 hover:underline'>
                <HiChevronLeft className=' w-6 h-6'/>
                <span className=' font-semibold'>Back</span>
              </button>
            </div>
            <div className=' text-right'>
              <button onClick={() => setDialogOpen(true)} type='button' className='h-max w-max rounded-md px-3 py-2 bg-[#ffebea] text-[#fe100e] hover:cursor-pointer'>Add New Transaction</button>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-10 mt-5'>
            <div>
              <h1 className=' font-semibold text-xl border-b border-gray-200 pb-2'>User Details</h1>
              <div className=' my-5 space-y-3'>
                <div className='flex items-center space-x-3'>
                  <p className=' font-semibold text-sm'>Username : </p>
                  <p className=' text-sm capitalize'>{updatesData['username']}</p>
                </div>
                <div className='flex items-center space-x-3'>
                  <p className=' font-semibold text-sm'>Name : </p>
                  <p className=' text-sm capitalize'>{updatesData['name']}</p>
                </div>
                <div className='flex items-center space-x-3'>
                  <p className=' font-semibold text-sm'>Email : </p>
                  <p className=' text-sm capitalize'>{updatesData['email']}</p>
                </div>
                <div className='flex items-center space-x-3'>
                  <p className=' font-semibold text-sm'>Phone Number : </p>
                  <p className=' text-sm capitalize'>{updatesData['phone_number']}</p>
                </div>
              </div>
            </div>
            <div>
              <h1 className=' font-semibold text-xl border-b border-gray-200 pb-2'>Current Subscription</h1>
              <div className=' my-5 space-y-3'>
                <div className='flex items-center space-x-3'>
                  <p className=' font-semibold text-sm'>Start Date : </p>
                  <p className=' text-sm capitalize'>{<Moment date={updatesData['subscription_start_date']} format='DD/MM/YYYY' />}</p>
                </div>
                <div className='flex items-center space-x-3'>
                  <p className=' font-semibold text-sm'>End Date : </p>
                  <p className=' text-sm capitalize'>{<Moment date={updatesData['subscription_end_date']} format='DD/MM/YYYY' />}</p>
                </div>
                <div className='flex items-center space-x-3'>
                  <p className=' font-semibold text-sm'>Status : </p>
                  <p className=' text-sm capitalize'>
                    {updatesData['subscription_status']}
                  </p>
                </div>
                <div className='flex items-center space-x-3'>
                  <p className=' font-semibold text-sm'>Subscription Type : </p>
                  <p className=' text-sm capitalize'>{updatesData['subscription_type']}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-10'>
          <div className='border-b border-gray-200 pb-2 flex items-center justify-between'>
            <h1 className=' font-semibold text-xl '>Transaction History</h1>
            <button disabled={downloadEntireReport.isLoading} onClick={handleDownloadReport} className=' text-blue-600 font-semibold text-sm disabled:opacity-20'>
              <div className='flex items-center space-x-3'>
                <span>Download Report</span>
                {downloadEntireReport.isLoading && <RxReload  className='animate-spin'/>}
              </div>
            </button>
          </div>
          <div className='overflow-x-auto relative border  shadow-sm border-slate-30npm run 0 sm:rounded-lg my-4'>
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-red-700 uppercase bg-red-100">
                <tr>
                  <th scope="col" className="py-3 px-6 text-left">
                    Bill Number
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Subscription start date
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Subscription end date
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Subscription Type
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Payment mode
                  </th>
                  <th scope="col" className="py-3 px-6 text-right">
                    Receipts
                  </th>
                  <th scope="col" className="py-3 px-6 text-right">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  subscriptionList?.isLoading ? (
                    <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                      <td className="py-4 px-7 text-center" colSpan={6}>
                        loading...
                      </td>
                    </tr>
                  ) : (
                    subscriptionList?.data?.data?.length !== 0 ? (
                      subscriptionList?.data?.data?.map((c) => {
                        const { bill_number, subscription_end_date, subscription_receipt, subscription_id, subscription_start_date, subscription_tag, subscription_type, transcation_id, year } = c;
                        return (
                          <tr className="bg-white border-b hover:bg-gray-50" key={transcation_id}>
                            <td scope="row" className="py-3 px-6 text-center ">
                              {bill_number}
                            </td>
                            <td className="py-3 px-6 text-center">
                              {/* {subscription_start_date} */}
                              <Moment date={subscription_start_date} format='DD/MM/YYYY' />
                            </td>
                            <td className="py-3 px-6 text-center">
                              {/* {subscription_end_date} */}
                              <Moment date={subscription_end_date} format='DD/MM/YYYY' />
                            </td>
                            <td className="py-3 px-6 text-center">
                              {subscription_type}
                            </td>
                            <td className="py-3 px-6 text-center">
                              {subscription_tag}
                            </td>
                            <td className="py-3 px-6 text-right">
                              {subscription_receipt === "1" &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mx-auto cursor-pointer" onClick={(e) => handleGenerateBill(e, transcation_id)}>
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>}
                            </td>
                            <td className="py-3 px-6 text-right" >
                              <div className=' flex items-center justify-center space-x-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer" onClick={() => handleEditTransaction(c)}>
                                  <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg> */}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                        <td className="py-4 px-7 text-center" colSpan={7}>
                          No Transaction found
                        </td>
                      </tr>
                    )
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
      <Dialog open={dialogOpen} onClose={handleCloseModal} sx={{ minWidth: '30%' }}>
        <form onSubmit={handleFormSubmit} className=' min-h-[30rem]'>
          <DialogContent>
            <h2 className='font-bold text-lg mb-6'>Add New Transaction</h2>
            <div className='grid grid-cols-2 auto-rows-auto gap-6 gap-x-6 min-w-[30rem]'>
              <FormElement
                name="subscription_id"
                label="Subscription"
                value={updatesData['subscription_id']}
                type="text"
                element="select"
                required={true}
                optionValue={subscriptionOption.isFetched ? handleSubscriptionOption() : [{ name: 'Select', value: '' }]}
                onChange={handleSetFormData}
              />
              <FormElement
                name="subscription_price"
                label="Amount"
                value={updatesData['subscription_price']}
                type="text"
                element="input"
                required={true}
                onChange={handleSetFormData}
              />
              <div>
                <div>
                  <label className='block text-gray-700 text-sm font-bold mb-2'>Start Date</label>
                </div>
                <DatePicker
                  selected={updatesData['subscription_start_date'] ? new Date(updatesData['subscription_start_date']) : ''}
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  dateFormat="dd/MM/yyyy"
                  onChange={setDate}
                />
              </div>
              <div>
                <label className='block text-gray-700 text-sm font-bold mb-2'>End Date</label>
                <DatePicker
                  selected={updatesData['subscription_end_date'] ? new Date(updatesData['subscription_end_date']) : ''}
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </div>
              <div>
                <label className='block text-gray-700 text-sm font-bold mb-2'>Payment Date</label>
                <DatePicker
                  selected={updatesData['payment_date'] ? new Date(updatesData['payment_date']) : ''}
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  dateFormat="dd/MM/yyyy"
                  onChange={setPaymentDate}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                color: "#fe100e",
                bgcolor: "#ffebea",
                borderColor: "#fe100e",
                border: "1px solid",
                ':hover': {
                  bgcolor: "#ffebea"
                }
              }}
              onClick={handleCloseModal}
            >Cancel</Button>
            <Button
              sx={{
                color: "white",
                bgcolor: "#fe100e",
                ':hover': {
                  bgcolor: "#fe100e"
                }
              }}
              disabled={setDataToServer.isLoading}
              type="submit"
            >
              {setDataToServer.isLoading && <RxReload className=' mr-2 animate-spin' />}
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} sx={{ minWidth: '25%' }}>
        <form onSubmit={handleUpdateFormSubmit}>
          <DialogContent>
            <h2 className='font-bold text-lg mb-6'>Edit Transaction</h2>
            <div className='grid grid-cols-2 auto-rows-auto gap-6 gap-x-6 min-w-[22rem]'>
            <FormElement
                name="subscription_id"
                label="Subscription"
                value={editTransactionData['subscription_id']}
                type="text"
                element="select"
                required={true}
                optionValue={subscriptionOption.isFetched ? handleSubscriptionOption() : [{ name: 'Select', value: '' }]}
                onChange={handleEditSetFormData}
              />
              <FormElement
                name="subscription_price"
                label="Amount"
                value={editTransactionData['subscription_price']}
                type="text"
                element="input"
                required={true}
                onChange={handleEditSetFormData}
              />
              <div>
                <div>
                  <label className='block text-gray-700 text-sm font-bold mb-2'>Start Date</label>
                </div>
                <DatePicker
                  // selected={new Date(editTransactionData['subscription_start_date'])}
                  selected={editTransactionData['subscription_start_date'] ? new Date(editTransactionData['subscription_start_date']) : ''}
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  dateFormat="dd/MM/yyyy"
                  onChange={setUpdateDate}
                />
              </div>
              <div>
                <label className='block text-gray-700 text-sm font-bold mb-2'>End Date</label>
                <DatePicker
                  // selected={new Date(editTransactionData['subscription_end_date'])}
                  selected={editTransactionData['subscription_end_date'] ? new Date(editTransactionData['subscription_end_date']) : ''}
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </div>
              <div>
                <label className='block text-gray-700 text-sm font-bold mb-2'>Payment Date</label>
                <DatePicker
                  selected={editTransactionData['payment_date'] ? new Date(editTransactionData['payment_date']) : ''}
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  dateFormat="dd/MM/yyyy"
                  onChange={setEditPaymentDate}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                color: "#fe100e",
                bgcolor: "#ffebea",
                borderColor: "#fe100e",
                border: "1px solid",
                ':hover': {
                  bgcolor: "#ffebea"
                }
              }}
              onClick={() => setEditDialogOpen(false)}
            >Cancel</Button>
            <Button
              sx={{
                color: "white",
                bgcolor: "#fe100e",
                ':hover': {
                  bgcolor: "#fe100e"
                }
              }}
              disabled={updateSubscription.isLoading}
              type="submit"
            >
              {updateSubscription.isLoading && <RxReload className=' mr-2' />}
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default UserDetail